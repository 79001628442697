import React from "react";

const triesMax = 5;
const transparentOnePx = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export default class LazyImage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isStarted: false,
      isLoaded: false,
    };

    this.triesCount = 1;
    this.imageRef = null;
  }

  componentDidMount() {
    this.checkScrollTimer = setInterval(this.checkScroll, 1000/60);
  }

  componentWillUnmount() {
    clearInterval(this.retryTimer);
    clearInterval(this.checkScrollTimer);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.state.isStarted !== nextState.isStarted
        || this.state.isLoaded !== nextState.isLoaded
        || this.props.src !== nextProps.src;
  }

  checkScroll = () => {
    const rect = this.imageRef.getBoundingClientRect();
    if (rect.y < window.innerHeight) {
      clearInterval(this.checkScrollTimer);
      this.load();
    }
  };

  load = () => {
    this.triesCount = 0;
    clearInterval(this.retryTimer);

    const image = new Image();

    image.onload = () => {
      this.setState({isLoaded: true});
    };

    image.onerror = () => {
      this.handleImageRetries(image);
    };

    image.src = this.props.src;

    this.setState({isStarted: true});
  };

  handleImageRetries = (image) => {
    this.setState({
      isLoaded: false
    }, () => {
      if (this.triesCount < triesMax) {
        this.retryTimer = setTimeout(() => {
          this.triesCount++;
          image.src = this.props.src;
        }, 1000);
      } else {
        this.setState({isLoaded: true});
      }
    });
  }

  handleClick = (e) => {
    this.props.onClick && this.props.onClick(e);
  };

  handleLoaded = (e) => {
    if (this.state.isStarted) {
      this.props.onLoaded && this.props.onLoaded(e);
    }
  };

  handleFailedToLoaded = (e) => {
    if (this.state.isStarted) {
      this.props.onFailedToLoad && this.props.onFailedToLoad(e);
    }
  };

  render() {
    return <img
      ref={(ref) => this.imageRef = ref}
      src={this.state.isStarted && this.state.isLoaded ? this.props.src : transparentOnePx}
      alt={this.props.alt}
      className={this.props.className}
      onLoad={this.handleLoaded}
      onError={this.handleFailedToLoaded}
      onClick={this.handleClick}
    />;
  }
}
