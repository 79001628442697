export function visibilitySensitiveTimeoutPromise(millis) {
  if (millis <= 0) {
    return Promise.reject(new Error("Timeout"));
  }

  return new Promise((resolve, reject) => {
    let isPlaying = true;
    const isPlayingChangeHandler = () => {
      isPlaying = document.visibilityState === "visible";
    };

    document.addEventListener("visibilitychange", isPlayingChangeHandler);

    let time = 0;
    const timer = setInterval(() => {
      if (isPlaying) {
        time++;
      }

      if (time * 1000 >= millis) {
        document.removeEventListener("visibilitychange", isPlayingChangeHandler);
        clearInterval(timer);
        reject(new Error("Timeout"));
      }
    }, 1000);
  });
}