import React from "react";
import {imageStatus} from "./shared";

export default function ImageView(props) {
  const {
    image,
    isSelectable,
    onClick,
    onLongClick
  } = props;

  const className = "image-item image-item--" + image.status;
  let touchTimer = null;

  function handleTouchStart() {
    touchTimer = setTimeout(() => onLongClick(image), 500);
  }

  function handleTouchEnd() {
    clearTimeout(touchTimer);
  }

  return <div
    className={className}
    onClick={() => onClick(image)}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchEnd}>
    {isSelectable && <React.Fragment>
      <input
        type="checkbox"
        id={image.id}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {}}
        checked={image.checked} />
      <label htmlFor={image.id}>
        <svg viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
      </label>
    </React.Fragment>}

    <div className="holder">
      <img src={image.url} alt="" />
    </div>

    {image.status === imageStatus.pending && <div className="pending-loader-container">
      <div className="pending-loader">
        <div className="pending-loader-slider" />
      </div>
    </div>}
  </div>;
}